import { render, staticRenderFns } from "./theme-3.vue?vue&type=template&id=10c1104c"
import script from "./theme-3.vue?vue&type=script&lang=js"
export * from "./theme-3.vue?vue&type=script&lang=js"
import style0 from "./theme-3.vue?vue&type=style&index=0&id=10c1104c&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsDownloadBanner: require('/var/app/components/Commons/DownloadBanner.vue').default,CommonsMaintenance: require('/var/app/components/Commons/Maintenance.vue').default,CommonsChangeLanguageLoading: require('/var/app/components/Commons/ChangeLanguageLoading.vue').default,Theme3ConfirmDepositDialog: require('/var/app/components/Theme3/ConfirmDepositDialog.vue').default,Theme3WithdrawalConfirmDeclineDialog: require('/var/app/components/Theme3/WithdrawalConfirmDeclineDialog.vue').default,Theme3MenuBar: require('/var/app/components/Theme3/MenuBar.vue').default,Theme3ImageBackground: require('/var/app/components/Theme3/ImageBackground.vue').default,Theme3MobileCustomFooter: require('/var/app/components/Theme3/Mobile/CustomFooter.vue').default,Theme3DesktopCustomFooter: require('/var/app/components/Theme3/Desktop/CustomFooter.vue').default,Theme3DesktopLoginModal: require('/var/app/components/Theme3/Desktop/LoginModal.vue').default,Theme3DesktopInterRegisterModal: require('/var/app/components/Theme3/Desktop/InterRegisterModal.vue').default,Theme3DesktopRegisterModal: require('/var/app/components/Theme3/Desktop/RegisterModal.vue').default,Theme3HoldBalanceDialog: require('/var/app/components/Theme3/HoldBalanceDialog.vue').default,CommonsThemeMode: require('/var/app/components/Commons/ThemeMode.vue').default})
